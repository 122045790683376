import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { SttHeading, SttMaskedInput, SttButton, SttAlerta, SttGrid } from '@stt-componentes/core';
import translate from './../translate';
import { validateBr } from 'js-brasil';

/**
 * Definição de estilos específicos da página
 */
const useStyles = makeStyles(theme => ({
    heading: {
        marginBottom: theme.spacing(4),
        color: props => {
            switch (props.basename) {
                case 'ufpa':
                    return '#ffffff';
            }
        },
        textTransform: props => {
            switch (props.basename) {
                case 'ufpa':
                    return 'uppercase';
            }
        },
        fontFamily: props => {
            switch (props.basename) {
                case 'ufpa':
                    return 'Sans-serif';
            }
        },
    },
    voltar: {
        margin: theme.spacing(3),
        textTransform: props => {
            switch (props.basename) {
                case 'ufpa':
                    return 'uppercase';
            }
        },
        fontFamily: props => {
            switch (props.basename) {
                case 'ufpa':
                    return 'Sans-serif';
            }
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    link: {
        textDecoration: 'none',
        color: 'inherit'
    }
}));

/**
 * Componente de login, com formulário e opção de recuperação de senha
 *
 * @param {Object} props
 */
const EsqueceuSenha = ({ strings }) => {
    // Classes css
    const classes = useStyles({ basename: global.gConfig.basename });
    // Variáveis de estado
    const [alertType, setAlertType] = useState('success');
    const [alert, showAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertOptions, setAlertOptions] = useState([]);
    const oauthBaseUrl = global.gConfig.url_base_api_oauth2;

    const handleClose = () => {
        showAlert(false);
    };


    // Esquema de validação do formulário
    const schema = Yup.object().shape({
        cpf: Yup
            .string()
            .nullable()
            .test('cpf-valido', strings.cpfInvalido, (cpf) => {
                cpf = cpf || '';

                if (cpf.length === 0) {
                    return true;
                }
                return validateBr.cpf(cpf);
            })
            .required(strings.campoObrigatorio)
    });

    const verificarUsuarioCadastrado = (values, { setSubmitting, resetForm }) => {
        const cpf = values.cpf.replace(/\D/g, '');
        const callbackSucesso = () => {
            forgotPassword(cpf);
            resetForm();
        }

        const callbackErro = () => {
            window.location.href = `${global.gConfig.urls_modulos.ADMINISTRATIVO}/solicitar-cadastro`;
        }

        axios
            .get(`${oauthBaseUrl}/auth/user/${cpf}`)
            .then(function (response) {
                const { data: { email } } = response;
                setAlertType('alert');
                setAlertTitle(strings.atencao);
                setAlertMessage(strings.mensagemConfirmacao.replace('%s', email).replace('%s', global.gConfig.email_admin_cadastros));
                setAlertOptions([{ title: strings.continuar, onClick: callbackSucesso }, { title: strings.voltar, onClick: handleClose }]);
                showAlert(!alert);
            })
            .catch(function (error) {
                console.log(error);
                setAlertType('alert');
                setAlertTitle(strings.atencao);
                setAlertMessage(strings.mensagemSemCadastro.replace('%s', values.cpf));
                setAlertOptions([{ title: strings.fazerCadastro, onClick: callbackErro }, { title: strings.voltar, onClick: handleClose }]);
                showAlert(!alert);
            })
            .finally(() => {
                setSubmitting(false);
            });
            
    }

    // Submete os dados para recuperação da senha
    const forgotPassword = (cpf) => {
        axios
            .post(`${oauthBaseUrl}/auth/forgot-password`, { cpf, baseName: global.gConfig.basename })
            .then(function (response) {
                const { data: { email } } = response;
                setAlertType('success');
                setAlertTitle(strings.emailEnviado);
                setAlertMessage(strings.mensagemSucesso.replace('%s', email));
                setAlertOptions([{ title: strings.ok, onClick: handleClose }]);
                showAlert(!alert);
            })
            .catch(function (error) {
                const { response } = error;
                const msg = response.data.message;
                setAlertType('error');
                setAlertTitle(strings.erro);
                setAlertMessage(msg);
                setAlertOptions([{ title: strings.ok, onClick: handleClose }]);
                showAlert(!alert);
            });
    };

    // Cria o formulário de autenticação
    return (
        <div>
            <Formik
                initialValues={{ cpf: null }}
                validationSchema={schema}
                onSubmit={verificarUsuarioCadastrado}
            >
                {({ isSubmitting, isValid, handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate className={classes.form}>
                        <SttGrid container direction='column' alignItems='center'>
                            <SttGrid item sm={12} xs={12}>
                                <SttHeading variant="h2"
                                    color="primary"
                                    className={classes.heading}>
                                    {strings.recuperarSenha}
                                </SttHeading>
                            </SttGrid>

                            <SttGrid item sm={12} xs={12}>
                                <Field name="cpf">
                                    {({ field, meta }) => (
                                        <>
                                            <SttMaskedInput
                                                {...field}
                                                mask="cpf"
                                                label={strings.cpf}
                                                required={true}
                                                error={meta.touched && meta.error ? true : false}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                            />
                                        </>
                                    )}
                                </Field>
                            </SttGrid>

                            <SttGrid item sm={12} xs={12}>
                                <SttHeading variant="h5"
                                    color="primary"
                                    className={classes.voltar}>
                                    <Link to="/"
                                        className={classes.link}>
                                        {strings.voltar}
                                    </Link>
                                </SttHeading>
                            </SttGrid>

                            <SttGrid item sm={12} xs={12}>
                                <SttButton
                                    color="primary"
                                    nomarginleft={1}
                                    type="submit"
                                    variant="contained"
                                    disabled={!isValid || isSubmitting}
                                >
                                    {strings.recuperarSenha}
                                </SttButton>
                            </SttGrid>
                        </SttGrid>
                    </form>
                )}
            </Formik>
            <SttAlerta
                open={alert}
                title={alertTitle}
                message={alertMessage}
                type={alertType}
                options={alertOptions}
                onClose={handleClose}
            />
        </div>
    );
};

export default translate('EsqueceuSenha')(EsqueceuSenha);

export const TIPO_USUARIO = {
    INTERNO: 'INTERNO',
    EXTERNO: 'EXTERNO'
}

export const MODULOS_CONSTANTES = {
    ADMINISTRATIVO: 'Administrativo',
    ALOFONO: 'AloFono',
    ANALISE: 'Análise',
    APAC: 'APAC',
    CONECTA: 'Conecta',
    EEG: 'EEG',
    ELETROCARDIOGRAFIA: 'ECG',
    ESPIROMETRIA: 'Espirometria',
    ESTOMATO: 'Estomatologia',
    GESTAOPACS: 'Gestão PACS',
    EXAMES: 'Exames',
    GESTAOPACS: 'Gestão PACS',
    HELPDESK: 'Suporte',
    MENSAGENS: 'Mensagens',
    OXIGENOTERAPIA: 'Oxigenoterapia',
    POLISSONOGRAFIA: 'Polissonografia',
    TELEATENDIMENTO: 'Consultório virtual',
    TELECONSULTORIAS: 'Teleconsultorias',
    TELEDERMATO: 'Dermatologia',
    TELEEDUCACAO: 'Tele-Educação',
    TFD: 'TFD',
    TFDPASSAGENS: 'TFD-Passagens'
};

export const VISUALIZADOR_IMAGEM = {
    GALERIA: 'G',
    OVIYAM: 'Y',
    OHIF: 'O'
}

export const ORIGEM_AUTENTICACAO_EXTERNA = {
    AZURE_AD: 'AZURE_AD'
}
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    SttGrid,
    SttTable,
    SttTableBody,
    SttTableCell,
    SttTableHead,
    SttTableRow,
    SttHeading,
    SttHidden,
    SttText,
} from '@stt-componentes/core';
import { withRouter } from "react-router-dom";
import translate from '../../translate';
import { sortByName } from '../../common/Functions';
const useStyles = makeStyles((theme) => ({
    table: {
        overflow: 'auto',
    },
    heading: {
        marginBottom: theme.spacing(1.5),
        marginTop: theme.spacing(1.5),
    },
    semResultado: {
        fontWeight: 'bold'
    },
    label: {
        alignSelf: 'center',
        marginLeft: '5px'
    }
}));

const MeusDadosModalidade = ({ instituicoes, redes, strings }) => {
    const classes = useStyles();

    const [modalidades, setModalidades] = useState([]);

    useEffect(() => {
        let mods = [];
        if (instituicoes.length > 0) {
            instituicoes.forEach(i => {
                if (i.modalidades?.length > 0) {
                    i.modalidades.forEach(m => {
                        mods.push({
                            nome: m.descricao,
                            instituicao: i.nome,
                            cnes: i.cnes,
                            rede: '-',
                            key: `${m.descricao}_${i.cnes}`
                        });
                    })
                }
            });
        }
        if (redes.length > 0) {
            redes.forEach(r => {
                if (r.modalidades?.length > 0) {
                    r.modalidades.forEach(m => {
                        mods.push({
                            nome: m.descricao,
                            instituicao: '-',
                            cnes: '-',
                            rede: r.descricao,
                            key: `${m.descricao}_${r.descricao}`
                        });
                    })
                }
            });
        }
        setModalidades(mods);
    }, []);

    return (
        <SttGrid container style={{ marginBottom: 12 }}>
            <SttGrid item xs={12}>
                <SttHeading variant="h4" color="primary" className={classes.heading}>
                    {strings.modalidade}
                </SttHeading>
            </SttGrid>
            <SttGrid item xs={12}>
                <SttTable stickyHeader className={classes.table}>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden xsDown>
                                <SttTableCell width="30%">{strings.descricao}</SttTableCell>
                                <SttTableCell width="30%">{strings.rede}</SttTableCell>
                                <SttTableCell width="30%">{strings.instituicao}</SttTableCell>
                                <SttTableCell width="10%">{strings.cnes}</SttTableCell>
                            </SttHidden>
                            <SttHidden smUp>
                                <SttTableCell width="100%">{strings.modalidade}</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            modalidades.length > 0 ? (
                                modalidades.sort(sortByName).map((mod) => (
                                    <SttTableRow key={mod.key}>
                                        <SttHidden xsDown>
                                            <SttTableCell>{mod.nome}</SttTableCell>
                                            <SttTableCell>{mod.rede}</SttTableCell>
                                            <SttTableCell>{mod.instituicao}</SttTableCell>
                                            <SttTableCell>{mod.cnes}</SttTableCell>
                                        </SttHidden>
                                        <SttHidden smUp>
                                            <SttTableCell width="100%">
                                                <SttText size="small">
                                                    <b>{strings.descricao}:</b> {mod.nome}
                                                </SttText>
                                                <SttText size="small">
                                                    <b>{strings.rede}:</b> {mod.rede}
                                                </SttText>
                                                <SttText size="small">
                                                    <b>{strings.instituicao}:</b> {mod.instituicao}
                                                </SttText>
                                                <SttText size="small">
                                                    <b>{strings.cnes}:</b> {mod.cnes}
                                                </SttText>
                                            </SttTableCell>
                                        </SttHidden>
                                    </SttTableRow>
                                ))
                            ) : (
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={4}
                                        align="center"
                                        className={classes.semResultado}>
                                        {strings.nenhumRegistroEncontrado}
                                    </SttTableCell>
                                </SttTableRow>
                            )
                        }
                    </SttTableBody>
                </SttTable>
            </SttGrid>
        </SttGrid>
    );
};

export default translate('MeusDadosModalidade')(withRouter(MeusDadosModalidade));
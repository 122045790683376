import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SttHeading, SttPaper, SttBox, SttLoading, SttGrid, SttNotification, SttAlertTitle } from '@stt-componentes/core';
import BannerComponent from '../components/banner';
import Util from '../util';
import { MODULOS_CONSTANTES } from '../common/AppConstants';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import translate from '../translate';
import { Cache } from '@stt-componentes/cache';

const useStyles = makeStyles((theme) => ({
    heading: {
        marginBottom: '20px',
        fontFamily: "Segoe UI, Arial, sans-serif",
        textTransform: 'uppercase'
    },
    gridItem: {
        display: 'flex',
        alignContent: 'center'
    },
    formColumn: {
        backgroundColor: '#fbfbfb',
        border: '1px solid',
        borderColor: '#D9D9D9',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '80%',
        marginTop: theme.spacing(5)
    },
    box: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    alert: {
        margin: theme.spacing(2)
    },
    alertTitle: {
        marginBottom: 0
    }
}));


const ModuloAcesso = ({ user, strings }) => {
    const classes = useStyles();
    const [carregado, setCarregado] = useState(false);
    const [modulosUsuario, setModulosUsuario] = useState([]);

    useEffect(() => {
        Cache.removeAllQueryParam();
    }, []);

    useEffect(() => {
        if (user) {
            setCarregado(true);

            let aux = [];
            if (user.modulos) {
                user.modulos.map(modulo => {
                    aux.push(
                        {
                            nome: modulo,
                            nomeFormatado: MODULOS_CONSTANTES[modulo]
                        }
                    )
                })
                
                aux.sort((a, b) => a.nomeFormatado.localeCompare(b.nomeFormatado));
            }

            setModulosUsuario(aux);
        }
    }, [user]);

    return (
        <>
            {
                carregado ?
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <SttPaper className={classes.formColumn}>
                                <SttBox className={classes.box} letterSpacing={1}>
                                    <SttGrid container className={classes.grid}>
                                        <>
                                            {
                                                modulosUsuario.map((modulo, index) => (
                                                    <SttGrid item xs={12} sm={6} md={4} lg={2} key={index}>
                                                        <BannerComponent
                                                            name={modulo.nomeFormatado}
                                                            icone={Util.retornarIconeModulo(modulo.nome)}
                                                            onClick={() => {
                                                                window.open(global.gConfig.urls_modulos[modulo.nome]);
                                                            }}
                                                        />
                                                    </SttGrid>
                                                ))
                                            }
                                            {
                                                (!user?.modulos?.length) &&
                                                <SttGrid item xs={12} className={classes.gridItem}>
                                                    <SttNotification severity="info" className={classes.alert}>
                                                        <SttAlertTitle className={classes.alertTitle}>{strings.alertaAusenciaModulos}</SttAlertTitle>
                                                    </SttNotification>
                                                </SttGrid>
                                            }
                                        </>
                                    </SttGrid>
                                </SttBox>
                            </SttPaper>
                        </div>
                    </> : <SttLoading
                        open={!carregado}
                        text={strings.carregando}
                    />
            }
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};


export default connect(mapStateToProps, null)(translate('ModuloAcesso')(withRouter(ModuloAcesso)));

import React, { useEffect } from 'react';
import { connectSocketHelpdesk } from '../common/Functions';

const AtalhoHelpdesk = () => {
    useEffect(() => {
        document.body.style.overflow = "hidden";
        const socket = connectSocketHelpdesk(global.gConfig);

        return () => {
            socket.disconnect();
        }
    }, []);

    return (
        <></>
    );
};

export default AtalhoHelpdesk;
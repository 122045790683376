import { combineReducers } from 'redux';
import TYPES from './types';

const INITIAL_STATE = {
    isAuthenticated: false,
    message: '',
    user: {
        nome: '',
        email: '',
        foto: ''
    }
};

const index = (state, action) => {
    if(!state) {
        state = INITIAL_STATE;
    }
    
    const { type, payload } = action;

    switch (type) {
        case TYPES.SET_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: payload.authenticated
            }
        case TYPES.SET_USER:
            return {
                ...state,
                user: payload.user
            }
        case TYPES.LOGOUT:
            return INITIAL_STATE;

        default:
            return state;
    }
}

export const Reducers = combineReducers({
    index
});

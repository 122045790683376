import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { SttHeading, SttText } from '@stt-componentes/core';
import translate from './../translate';

/**
 * Definição de estilos específicos da página
 */
const useStyles = makeStyles(theme => ({
    heading: {
        marginTop: '20px',
        marginBottom: '30px'
    },
    link: {
        textDecoration: 'none',
        color: 'inherit'
    }
}));
const TokenInvalido = ({ strings }) => {
    const classes = useStyles();

    return (
        <div>
            <SttHeading variant="h2"
                color="primary"
                className={classes.heading}>
                {strings.tokenInvalido}
            </SttHeading>
            <SttText>
                O token que você está utiliando não é válido. A causa mais provável é que o tempo de validade do token tenha expirado.
                Se você chegou nesta tela através do link de recuperação de senha, tente solicitar um novo link.
                Se esse problema persistir, entre em contato com os administradores do sistema.
            </SttText>
            <SttHeading variant="h5"
                color="primary"
                className={classes.heading}>
                <Link to="/"
                    className={classes.link}>
                    {strings.voltar}
                </Link>
            </SttHeading>
        </div>
    );
};

export default translate('TokenInvalido')(TokenInvalido);

import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import theme from './theme';
import Index from './paginas';
import { Store } from './store';
import PropTypes from 'prop-types';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { CacheManagerCRT, CacheManagerSRT } from './iframes/CacheManager';
import MenuMeusDados from './iframes/MenuMeusDados';
import MenuModulosManager from './iframes/MenuModulosManager';
import AtalhoHelpdesk from './iframes/AtalhoHelpdesk';
import { MsalProvider } from "@azure/msal-react";

const Rotas = () => {
    return <BrowserRouter basename={`/${global.gConfig.basename}`}>
        <Switch>
            <Route
                path="/CacheManager"
                exact
                component={CacheManagerCRT}
            />
            <Route
                path="/CacheManagerCRT"
                exact
                component={CacheManagerCRT}
            />
            <Route
                path="/CacheManagerSRT"
                exact
                component={CacheManagerSRT}
            />
            <Route
                path="/MenuModulosManager"
                exact
                component={MenuModulosManager}
            />
            <Route
                path="/MenuMeusDados"
                exact
                component={MenuMeusDados}
            />
            <Route
                path="/AtalhoHelpdesk"
                exact
                component={AtalhoHelpdesk}
            />
            <Route
                path="/"
            >
                <Provider store={Store}>
                    <ThemeProvider theme={theme}>
                        <Index />
                    </ThemeProvider>
                </Provider>
            </Route>
        </Switch>
    </BrowserRouter>
}

class App extends Component {
    render() {
        const { pca } = this.props;
        return (
            pca ? (
                <MsalProvider instance={pca}>
                    < Rotas />
                </MsalProvider >
            ) : (
                <Rotas />
            )
        );
    }

    getChildContext() {
        return {
            modulo: this.props.modulo,
            currentLanguage: this.props.currentLanguage
        };
    }
}

App.childContextTypes = {
    modulo: PropTypes.string.isRequired,
    currentLanguage: PropTypes.string.isRequired
};

export default App;

import React, { memo, useState } from 'react';
import {
    SttAlerta,
    SttHeading,
    SttPaper,
    SttTranslate
} from '@stt-componentes/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { getBearerHeaders } from '../requests';
import HttpStatus from 'http-status-codes';

import SttSenha from '@stt-componentes/senha';

const useStyles = makeStyles((theme) => ({
    formColumn: {
        backgroundColor: theme.palette.background.default,
        border: '2px solid',
        borderColor: '#D9D9D9',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        width: '80%',
        marginTop: theme.spacing(5)
    },
    container: {
        display: 'flex',
        justifyContent: 'center'
    },
    heading: {
        marginBottom: theme.spacing(1.5),
        marginTop: theme.spacing(1.5)
    }
}));

const Alerta = memo((props) => {
    return <SttAlerta {...props} />;
});

const MeusDadosFormSenha = ({ strings, user }) => {
    const classes = useStyles();

    //Alerta
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [opcoesAlerta, setOpcoesAlerta] = useState([
        {
            title: 'strings.ok',
            onClick: () => {
                setMostrarAlerta(false);
            }
        }
    ]);
    const [onClose, setOnClose] = useState(() => setMostrarAlerta(false));
    const dadosUsuario = {
        idUsuario: user.id,
        nome: user.nome?.replace(/\ /g, ''),
        cpf: user.cpf?.replace(/[.-]/g, ''),
        login: user.login?.replace(/\ /g, ''),
        email: user.email
    };
    const initialValues = {
        confirmacaoSenha: '',
        novaSenha: '',
        senhaAtual: ''
    };

    const enviarForm = (dados, setSubmitting, resetForm) => {
        const oauthBaseUrl = global.gConfig.url_base_api_oauth2;
        const { verificarSenha } = dados;
        setMostrarAlerta(false);
        setSubmitting(true);
        axios
            .post(`${oauthBaseUrl}/interno/update-user-password`, dados, {
                headers: getBearerHeaders()
            })
            .then(function (response) {
                const { data } = response;
                setSubmitting(false);
                setTituloAlerta(strings.sucesso);
                setMensagemAlerta(data?.message || strings.operacaoSucesso);
                setTipoAlerta('success');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                            resetForm({ values: initialValues });
                        }
                    }
                ]);
                setOnClose(() => () => {
                    setMostrarAlerta(false);
                    resetForm({ values: initialValues });
                    verificarSenha('', '');
                });
                setMostrarAlerta(true);
            })
            .catch(function (err) {
                const { response } = err;

                setSubmitting(false);
                let mensagem = strings.erro;
                if (response) {
                    const { data } = response;
                    mensagem = data.errors;
                    if (
                        response.status === HttpStatus.BAD_REQUEST ||
                        response.status === HttpStatus.INTERNAL_SERVER_ERROR
                    ) {
                        let arrMensagem = [];
                        if (mensagem) {
                            mensagem.forEach((error) => {
                                arrMensagem.push(`- ${error}\n`);
                            });
                        }
                        if (arrMensagem.length > 0) {
                            mensagem = arrMensagem;
                        }
                    }
                }
                setTituloAlerta(strings.erro);
                setMensagemAlerta(mensagem);
                setTipoAlerta('error');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => setMostrarAlerta(false)
                    }
                ]);
                setOnClose(() => () => setMostrarAlerta(false));
                setMostrarAlerta(true);
            });
    };

    return (
        <div className={classes.container}>
            <SttPaper className={classes.formColumn}>
                <SttHeading
                    className={classes.heading}
                    variant="h2"
                    color="primary"
                >
                    <b>{strings.alteracaoSenha}</b>
                </SttHeading>
                <SttSenha
                    strings={strings}
                    dadosUsuario={dadosUsuario}
                    enviarForm={enviarForm}
                />
            </SttPaper>
            <Alerta
                open={mostrarAlerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={onClose}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(
    mapStateToProps,
    null
)(SttTranslate('MeusDadosFormSenha')(withRouter(MeusDadosFormSenha)));

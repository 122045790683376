import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../hooks';
import Form from './form-nova-senha';
import { SttCircularProgress as SttCarregando } from '@stt-componentes/core';

/**
 * Componente de login, com formulário e opção de recuperação de senha
 *
 * @param {Object} props
 */
const RedefinirSenha = (props) => {
    // Permite acesso à query da URL
    const query = useQuery();

    // Fornece aceso a funções de redirecionamento
    const history = useHistory();
    const [validationDone, setValidationDone] = useState(false);
    const [validToken, setValidToken] = useState(false);

    // Obtém o token da URL
    const token = query.get('token');
    useEffect(() => {
        const oauthBaseUrl = global.gConfig.url_base_api_oauth2;
        if (token) {
            // Verifica validade do token
            axios
                .get(`${oauthBaseUrl}/auth/validate-token`, { params: { token: token } })
                .then(function (response) {
                    setValidToken(true);
                    setValidationDone(true);
                })
                .catch(function (error) {
                    /*eslint-disable */
                    console.log(error);
                    /* eslint-enable */
                    setValidationDone(true);
                });
        } else {
            history.replace('/token-invalido');
        }
    }, []);

    // Renderiza o componente adequado.
    return (
        validationDone ? (validToken ? <Form token={token} /> : <div>{history.replace('/token-invalido')}</div>) : <SttCarregando />
    );
};

export default RedefinirSenha;

import React, { useState, memo, useEffect } from "react";
import {
    SttContainer,
    SttLoading,
    SttAlerta,
    SttGrid,
    SttButton,
    SttFormControl,
    SttFormControlLabel,
    SttFormGroup,
    SttCheckbox,
    SttHeading,
    SttRadioButton,
    SttRadioGroup,
    SttNotification,
    SttTranslate
} from '@stt-componentes/core';
import HttpStatus from 'http-status-codes';
import {
    Formik,
    FastField,
    Field
} from 'formik';
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios';
import { getBearerHeaders } from '../../requests';
import * as yup from 'yup';
import { VISUALIZADOR_IMAGEM } from "../../common/AppConstants";

const validationSchema = () => {
    return yup.object().shape({
        'autorizarDivulgacao': yup
            .bool(),
        'divulgacaoEmail': yup
            .bool(),
    });
}

const useStyles = makeStyles(theme => ({
    container: {
        paddingLeft: 0
    },
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    notificacao: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    heading: {
        marginBottom: theme.spacing(1.5),
        marginTop: theme.spacing(1.5),
    },
    heading: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1.5),
    },
}));


const Alerta = memo((props) => {
    return (
        <SttAlerta {...props} />
    )
});

const MeusDadosForm = ({ strings, user }) => {
    const classes = useStyles();

    const [initialValues, setInitialValues] = useState(null);

    //Alerta
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [opcoesAlerta, setOpcoesAlerta] = useState([{
        title: strings.ok,
        onClick: () => {
            setMostrarAlerta(false);
        }
    }]);
    const [onClose, setOnClose] = useState(() => setMostrarAlerta(false));

    useEffect(() => {
        if (user) {
            setInitialValues({
                acessoWeasis: user.acessoWeasis || 'W',
                autorizarDivulgacao: user.autorizarDivulgacao ?? false,
                autorizarDivulgacaoOriginal: user.autorizarDivulgacao ?? false,
                divulgacaoEmail: user.divulgacaoEmail ?? false,
                divulgacaoEmailOriginal: user.divulgacaoEmail ?? false,
            });
        }
    }, [user]);

    const enviarForm = (dados, setSubmitting) => {
        const oauthBaseUrl = global.gConfig.url_base_api_oauth2;
        setMostrarAlerta(false);
        setSubmitting(true);
        axios.post(`${oauthBaseUrl}/interno/update-user`, dados, { headers: getBearerHeaders() })
            .then(function (response) {
                const { data } = response;
                setSubmitting(false);
                setTituloAlerta(strings.sucesso);
                setMensagemAlerta(data?.message || strings.operacaoSucesso);
                setTipoAlerta('success');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => window.location.reload()
                    }
                ]);
                setOnClose(() => () => window.location.reload());
                setMostrarAlerta(true);
            })
            .catch(function (err) {
                const { response } = err;

                setSubmitting(false);
                let mensagem = strings.erro;
                if (response) {
                    const { data } = response;
                    mensagem = data.message;
                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR && data.errors) {
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }
                setTituloAlerta(strings.erro);
                setMensagemAlerta(mensagem);
                setTipoAlerta('error');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => setMostrarAlerta(false)
                    }
                ]);
                setOnClose(() => () => setMostrarAlerta(false));
                setMostrarAlerta(true);
            });
    }

    return (
        <>
            {
                initialValues &&
                <Formik
                    initialValues={initialValues}
                    validateOnChange={false}
                    validationSchema={validationSchema(strings)}
                    onSubmit={(dados, { setSubmitting }) => {
                        setSubmitting(false);
                        setTituloAlerta(strings.atencao);
                        setMensagemAlerta(strings.confirmarAlteracaoSenha);
                        setTipoAlerta('alert');
                        setOpcoesAlerta([
                            {
                                title: strings.sim,
                                onClick: () => enviarForm(dados, setSubmitting)
                            },
                            {
                                title: strings.nao,
                                onClick: () => setMostrarAlerta(false)
                            }
                        ]);
                        setOnClose(() => () => setMostrarAlerta(false));
                        setMostrarAlerta(true);
                    }}
                >
                    {
                        ({
                            isSubmitting,
                            values,
                            handleSubmit
                        }) => (
                            <>
                                <SttContainer className={classes.container}>
                                    <form onSubmit={handleSubmit} noValidate>
                                        <SttGrid container spacing={1}>
                                            <SttGrid item xs={12}>
                                                <SttHeading variant="h4" color="primary">{global.gConfig.autorizar_divulgacao}</SttHeading>
                                                <Field name={'autorizarDivulgacao'}>
                                                    {({
                                                        field,
                                                        form: { setFieldValue },
                                                    }) => (
                                                        <SttFormControl variant="outlined">
                                                            <SttRadioGroup row
                                                                onChange={(e, item) => {
                                                                    setFieldValue('autorizarDivulgacao', item === 'true');
                                                                    setFieldValue('divulgacaoEmail', false);
                                                                }
                                                                }
                                                            >
                                                                <SttFormControlLabel
                                                                    {...field}
                                                                    control={
                                                                        <SttRadioButton
                                                                            type="radio"
                                                                            value={true}
                                                                            color="primary"
                                                                            checked={field.value}
                                                                        />
                                                                    }
                                                                    label={strings.sim}
                                                                />
                                                                <SttFormControlLabel
                                                                    {...field}
                                                                    control={
                                                                        <SttRadioButton
                                                                            type="radio"
                                                                            value={false}
                                                                            color="primary"
                                                                            checked={!field.value}
                                                                        />
                                                                    }
                                                                    label={strings.nao}
                                                                />
                                                            </SttRadioGroup>
                                                        </SttFormControl>
                                                    )}
                                                </Field>
                                            </SttGrid>
                                            {
                                                values.autorizarDivulgacao &&
                                                <SttGrid item xs={12}>
                                                    <Field name={'divulgacaoEmail'}>
                                                        {({
                                                            field,
                                                            form: { values }
                                                        }) => (
                                                            <SttFormControl variant="outlined">
                                                                <SttFormGroup row>
                                                                    <SttFormControlLabel
                                                                        control={
                                                                            <SttCheckbox
                                                                                {...field}
                                                                                value={true}
                                                                                color="primary"
                                                                                checked={values.divulgacaoEmail}
                                                                            />
                                                                        }
                                                                        label={strings.divulgacaoEmail}
                                                                    />
                                                                </SttFormGroup>
                                                            </SttFormControl>
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                            }
                                            <SttGrid item xs={12}>
                                                <SttNotification severity="info" className={classes.notificacao}>
                                                    {strings.infoDivulgacao}
                                                </SttNotification>
                                            </SttGrid>
                                            <SttGrid item xs={12}>
                                                <SttHeading variant="h5" color="primary" className={classes.heading}>{strings.acessoWeasis}</SttHeading>
                                                <Field name='acessoWeasis'>
                                                    {({
                                                        field,
                                                        form: { setFieldValue },
                                                    }) => (
                                                        <SttFormControl variant="outlined">
                                                            <SttRadioGroup onChange={(e, item) => { setFieldValue('acessoWeasis', item) }}>
                                                                <SttFormControlLabel
                                                                    {...field}
                                                                    control={
                                                                        <SttRadioButton
                                                                            type="radio"
                                                                            value={'W'}
                                                                            color="primary"
                                                                            checked={field.value === 'W'}
                                                                        />
                                                                    }
                                                                    label={strings.downloadWeasis}
                                                                />
                                                                <SttFormControlLabel
                                                                    {...field}
                                                                    control={
                                                                        <SttRadioButton
                                                                            type="radio"
                                                                            value={'L'}
                                                                            color="primary"
                                                                            checked={field.value === 'L'}
                                                                        />
                                                                    }
                                                                    label={strings.instaladoWeasis}
                                                                />
                                                                <SttNotification severity="info" className={classes.notificacao}>
                                                                    {strings.avisoWeasis}
                                                                    &nbsp;<a target="_blank" rel="noopener" href="https://nroduit.github.io/en/getting-started/#try-weasis-now">CLIQUE AQUI</a>.
                                                                </SttNotification>
                                                            </SttRadioGroup>
                                                        </SttFormControl>
                                                    )}
                                                </Field>
                                            </SttGrid>
                                        </SttGrid>
                                        <div className={classes.buttonWrapper}>
                                            <SttButton
                                                type="submit"
                                                variant="contained"
                                                nomarginleft="true"
                                                className={classes.button}
                                                color="primary"
                                                disabled={isSubmitting}
                                            >
                                                {strings.salvar}
                                            </SttButton>
                                        </div>
                                    </form>
                                    <SttLoading
                                        open={isSubmitting}
                                        text={strings.salvandoMensagemEspera}
                                    />
                                </SttContainer>

                            </>
                        )

                    }
                </Formik>
            }
            <Alerta
                open={mostrarAlerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={onClose}
            />

        </>
    );
}

export default SttTranslate('MeusDadosForm')(MeusDadosForm);
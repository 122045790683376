import React, { useState } from 'react';
import axios from 'axios';
import {
    SttButton,
    SttAlerta,
    SttLoading,
    SttGrid,
    SttModal,
    SttText,
    SttFormControlLabel,
    SttCheckbox
} from '@stt-componentes/core';
import translate from './../translate';
import { getHeaders } from '../requests';
import { Cache } from '@stt-componentes/cache';

const TermoUsoForm = ({ strings, accessToken, refreshToken, redirectUrl, setModalTermoUso }) => {
    //Alerta
    const [openCarregando, setOpenCarregando] = useState(false);
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [opcoesAlerta, setOpcoesAlerta] = useState([{
        title: strings.ok,
        onClick: () => {
            setMostrarAlerta(false);
        }
    }]);
    const [onClose, setOnClose] = useState(() => setMostrarAlerta(false));

    const [termoAceito, setTermoAceito] = useState(false);

    const aceitarTermoUso = () => {
        setOpenCarregando(true);
        axios.post(`${global.gConfig.url_base_api_oauth2}/interno/accept-terms-of-use`, {}, { headers: getHeaders(accessToken) })
            .then(function (response) {
                Cache.setAccessToken(accessToken);
                Cache.setRefreshToken(refreshToken);
                window.location.assign(redirectUrl);
            })
            .catch(function (error) {
                const { response } = error;
                const msg = response.data.message;

                setOpcoesAlerta([
                    {
                        title: strings.fechar,
                        onClick: () => setMostrarAlerta(false)
                    }
                ]);
                setOnClose(() => () => setMostrarAlerta(false));
                setTipoAlerta('error');
                setTituloAlerta(strings.erro);
                setMensagemAlerta(msg);
                setMostrarAlerta(true);
            })
            .finally(() => {
                setOpenCarregando(false);
            });
    };

    return (
        <>
            <SttLoading
                open={openCarregando}
                text={strings.salvando}
            />
            <SttAlerta
                open={mostrarAlerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={onClose}
            />
            <SttModal
                title={strings.tituloModal}
                open={!!accessToken}
                outModalClose={() => setModalTermoUso(null)}
                iconClose={() => setModalTermoUso(null)}
                maxWidth="lg"
                fullWidth={true}
                children={
                    <div style={{ overflow: 'hidden', padding: '16px' }}>
                        <SttText dangerouslySetInnerHTML={{ __html: strings.textoModal }} />
                        <SttGrid container spacing={1}>
                            <SttFormControlLabel
                                control={
                                    <SttCheckbox
                                        onChange={() => setTermoAceito(t => !t)}
                                        value={termoAceito}
                                        color="primary"
                                    />
                                }
                                label={strings.liConcordo}
                            />
                        </SttGrid>
                    </div>
                }
                footer={
                    <>
                        <SttButton variant="contained"
                            color="primary"
                            disabled={!termoAceito}
                            onClick={aceitarTermoUso}>
                            {strings.confirmar}
                        </SttButton>
                    </>
                }
            />
        </>
    );
};

export default translate('TermoUsoForm')(TermoUsoForm);
import { connect } from "socket.io-client";
import { getAuthrizationHeader } from "../requests";

export const sortByName = (a, b) => {
    const nomeA = (a.nome || '').toUpperCase(); // Convertendo para letras maiúsculas para garantir que a ordenação seja case-insensitive
    const nomeB = (b.nome || '').toUpperCase();

    if (nomeA < nomeB) {
        return -1;
    }
    if (nomeA > nomeB) {
        return 1;
    }
    return 0;
};

export const connectSocketHelpdesk = (config) => {
    const options = {
        forceNew: false,
        extraHeaders: {
            ...getAuthrizationHeader()
        },
        reconnectionDelay: 10000, // defaults to 1000
        reconnectionDelayMax: 10000 // defaults to 5000
    };
    if (config.url_base_socket_helpdesk_path !== '') {
        options.path = config.url_base_socket_helpdesk_path;
    }
    const socket = connect(config.url_base_socket_helpdesk, options);

    socket.on("connect", () => {
        socket.emit('status_atendente');
        socket.on('atendente_online', (dados) => {
            // Recebimento de uma atualização no status de atendentes conectados.
            const dadosrecebidos = JSON.parse(dados);
            const message = {
                "acao": "STATUS_ATENDENTE_CONECTADO_HELPDESK",
                "data": {
                    online: dadosrecebidos.atendenteOnline,
                    urlHelpdesk: global.gConfig.urls_modulos['HELPDESK']
                },
            };
            window.parent.postMessage(message, "*");
        });
    });

    return socket;
}
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    SttGrid,
    SttTable,
    SttTableBody,
    SttTableCell,
    SttTableHead,
    SttTableRow,
    SttHeading,
    SttHidden,
    SttText,
} from '@stt-componentes/core';
import { withRouter } from "react-router-dom";
import translate from '../../translate';
import { sortByName } from '../../common/Functions';
const useStyles = makeStyles((theme) => ({
    table: {
        overflow: 'auto',
    },
    heading: {
        marginBottom: theme.spacing(1.5),
        marginTop: theme.spacing(1.5),
    },
    semResultado: {
        fontWeight: 'bold'
    },
    label: {
        alignSelf: 'center',
        marginLeft: '5px'
    }
}));

const MeusDadosSetor = ({ instituicoes, strings }) => {
    const classes = useStyles();

    const [setores, setSetores] = useState([]);

    useEffect(() => {
        let sets = [];
        if (instituicoes.length > 0) {
            instituicoes.forEach(i => {
                if (i.setores?.length > 0) {
                    i.setores.forEach(s => {
                        sets.push({
                            nome: s.descricao,
                            instituicao: i.nome,
                            cnes: i.cnes,
                            key: `${s.descricao}_${i.cnes}_${s.id}`
                        });
                    })
                }
            });
        }
        setSetores(sets);
    }, []);

    return (
        <SttGrid container style={{ marginBottom: 12 }}>
            <SttGrid item xs={12}>
                <SttHeading variant="h4" color="primary" className={classes.heading}>
                    {strings.setor}
                </SttHeading>
            </SttGrid>
            <SttGrid item xs={12}>
                <SttTable stickyHeader className={classes.table}>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden xsDown>
                                <SttTableCell width="45%">{strings.descricao}</SttTableCell>
                                <SttTableCell width="45%">{strings.instituicao}</SttTableCell>
                                <SttTableCell width="10%">{strings.cnes}</SttTableCell>
                            </SttHidden>
                            <SttHidden smUp>
                                <SttTableCell width="100%">{strings.setor}</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            setores.length > 0 ? (
                                setores.sort(sortByName).map((set) => (
                                    <SttTableRow key={set.key}>
                                        <SttHidden xsDown>
                                            <SttTableCell>{set.nome}</SttTableCell>
                                            <SttTableCell>{set.instituicao}</SttTableCell>
                                            <SttTableCell>{set.cnes}</SttTableCell>
                                        </SttHidden>
                                        <SttHidden smUp>
                                            <SttTableCell width="100%">
                                                <SttText size="small">
                                                    <b>{strings.descricao}:</b> {set.nome}
                                                </SttText>
                                                <SttText size="small">
                                                    <b>{strings.instituicao}:</b> {set.instituicao}
                                                </SttText>
                                                <SttText size="small">
                                                    <b>{strings.cnes}:</b> {set.cnes}
                                                </SttText>
                                            </SttTableCell>
                                        </SttHidden>
                                    </SttTableRow>
                                ))
                            ) : (
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={3}
                                        align="center"
                                        className={classes.semResultado}>
                                        {strings.nenhumRegistroEncontrado}
                                    </SttTableCell>
                                </SttTableRow>
                            )
                        }
                    </SttTableBody>
                </SttTable>
            </SttGrid>
        </SttGrid>
    );
};

export default translate('MeusDadosSetor')(withRouter(MeusDadosSetor));
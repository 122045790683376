"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SENHA_ATUAL = exports.NOVA_SENHA = exports.IDUSUARIO = exports.CONFIRMACAO_SENHA = void 0;
var NOVA_SENHA = 'novaSenha';
exports.NOVA_SENHA = NOVA_SENHA;
var CONFIRMACAO_SENHA = 'confirmacaoSenha';
exports.CONFIRMACAO_SENHA = CONFIRMACAO_SENHA;
var SENHA_ATUAL = 'senhaAtual';
exports.SENHA_ATUAL = SENHA_ATUAL;
var IDUSUARIO = 'idUsuario';
exports.IDUSUARIO = IDUSUARIO;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.colors = exports.checkList = void 0;
var colors = ["#d38f81", "#f0b3b3", "#fbd9b6", "#ffe599", "#b5d4a8", "#93c47d"];
exports.colors = colors;
var checkList = function checkList(strings) {
  return [{
    chave: 'oitoCaracteres',
    mensagem: strings.checkList8Caracteres
  }, {
    chave: 'caractereAlfabetico',
    mensagem: strings.checkListAlfabetico
  }, {
    chave: 'caractereNumerico',
    mensagem: strings.checkListNumerico
  }, {
    chave: 'caracterEspecial',
    mensagem: strings.checkListEspecial
  }, {
    chave: 'letraMinuscula',
    mensagem: strings.checkListMinuscula
  }, {
    chave: 'letraMaiuscula',
    mensagem: strings.checkListMaiuscula
  }];
};
exports.checkList = checkList;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var yup = _interopRequireWildcard(require("yup"));
var _moment = _interopRequireDefault(require("moment"));
var _fieldNames = require("./util/constants/fieldNames");
var _dataTypes = require("./util/constants/dataTypes");
var _stringSimilarityJs = require("string-similarity-js");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var _default = function _default(strings, dadosUsuario) {
  var _yup$object$shape;
  var idUsuario = dadosUsuario.idUsuario;
  var dataSchema = function dataSchema(dadosUsuario) {
    var nome = dadosUsuario.nome,
      dataNascimento = dadosUsuario.dataNascimento,
      cpf = dadosUsuario.cpf,
      login = dadosUsuario.login,
      email = dadosUsuario.email;
    return {
      nomeUsuario: nome === null || nome === void 0 ? void 0 : nome.replace(/\ /g, ""),
      dataNascimentoUsuario: (0, _moment["default"])(dataNascimento).format("DDMMYYYY"),
      cpfUsuario: cpf === null || cpf === void 0 ? void 0 : cpf.replace(/[.-]/g, ""),
      loginUsuario: login === null || login === void 0 ? void 0 : login.replace(/\ /g, ""),
      emailUsuario: email
    };
  };
  var _dataSchema = dataSchema(dadosUsuario),
    nomeUsuario = _dataSchema.nomeUsuario,
    dataNascimentoUsuario = _dataSchema.dataNascimentoUsuario,
    cpfUsuario = _dataSchema.cpfUsuario,
    loginUsuario = _dataSchema.loginUsuario,
    emailUsuario = _dataSchema.emailUsuario;
  var testeSimilaridade = function testeSimilaridade(_ref, _ref2) {
    var novaSenha = _ref.novaSenha;
    var dado = _ref2.dado,
      TIPO = _ref2.TIPO;
    var senha_formatada = novaSenha === null || novaSenha === void 0 ? void 0 : novaSenha.replace(/\ /g, "");
    var limte_similaridade = 0.6;
    var similaridade = 0;
    if (!novaSenha || !dado) {
      return true;
    }
    if (TIPO === _dataTypes.NOME) {
      senha_formatada = novaSenha.replace(/\d/g, "").replace(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g, "");
      limte_similaridade = 0.4;
    }
    if (TIPO === _dataTypes.DATA_NASCIMENTO || TIPO === _dataTypes.CPF) {
      senha_formatada = novaSenha.replace(/[a-zA-Z]/g, "").replace(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g, "");
    }
    if (TIPO === _dataTypes.LOGIN) {
      if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(dado)) {
        senha_formatada = senha_formatada.replace(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g, "");
      }
      if (!/[A-Za-z]/.test(dado)) {
        senha_formatada = senha_formatada.replace(/[A-Za-z]/g, "");
      }
      if (!/\d/.test(dado)) {
        senha_formatada = senha_formatada.replace(/\d/g, "");
      }
    }
    if (TIPO === _dataTypes.EMAIL) {
      if (!/@.*\.com$/.test(senha_formatada)) {
        dado = dado.replace(/@.*\.com$/, "");
      }
    }
    similaridade = (0, _stringSimilarityJs.stringSimilarity)(senha_formatada, dado);
    if (similaridade >= limte_similaridade) {
      return false;
    } else {
      return true;
    }
  };
  var testeNovaSenhaConfirmacaoIguais = function testeNovaSenhaConfirmacaoIguais(parent) {
    if (!parent.novaSenha || !parent.confirmacaoSenha) {
      return true;
    }
    return parent.novaSenha === parent.confirmacaoSenha;
  };
  return yup.object().shape((_yup$object$shape = {}, _defineProperty(_yup$object$shape, _fieldNames.SENHA_ATUAL, idUsuario && yup.string().nullable().trim().required(strings.campoObrigatorio)), _defineProperty(_yup$object$shape, _fieldNames.NOVA_SENHA, yup.string().nullable().trim().required(strings.campoObrigatorio).min(8, strings.senhaMinimo9Caracteres).matches(/^[^\s]+$/, strings.senhaSemEspacos).matches(/\d/, strings.senhaAoMenosUmNumero).matches(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/, strings.senhaAoMenosUmCaracter).matches(/[a-z]/, strings.senhaAoMenosUmaMinuscula).matches(/[A-Z]/, strings.senhaAoMenosUmaMaiuscula).test("senhaNaoContemNome", strings.novaSenhaConfirmacaoNome, function () {
    return testeSimilaridade(this.parent, {
      dado: nomeUsuario,
      TIPO: _dataTypes.NOME
    });
  }).test("senhaNaoContemDataNascimento", strings.novaSenhaConfirmacaoDataNascimento, function () {
    return testeSimilaridade(this.parent, {
      dado: dataNascimentoUsuario,
      TIPO: _dataTypes.DATA_NASCIMENTO
    });
  }).test("senhaNaoContemCpf", strings.novaSenhaConfirmacaoCpf, function () {
    return testeSimilaridade(this.parent, {
      dado: cpfUsuario,
      TIPO: _dataTypes.CPF
    });
  }).test("senhaNaoContemLogin", strings.novaSenhaConfirmacaoLogin, function () {
    return testeSimilaridade(this.parent, {
      dado: loginUsuario,
      TIPO: _dataTypes.LOGIN
    });
  }).test("senhaNaoContemEmail", strings.novaSenhaConfirmacaoEmail, function () {
    return testeSimilaridade(this.parent, {
      dado: emailUsuario,
      TIPO: _dataTypes.EMAIL
    });
  }).test("senhasIguais", "", function () {
    return testeNovaSenhaConfirmacaoIguais(this.parent);
  })), _defineProperty(_yup$object$shape, _fieldNames.CONFIRMACAO_SENHA, yup.string().nullable().trim().required(strings.campoObrigatorio).test("senhasIguais", strings.novaSenhaConfirmacaoSenhaIgual, function () {
    return testeNovaSenhaConfirmacaoIguais(this.parent);
  })), _yup$object$shape));
};
exports["default"] = _default;
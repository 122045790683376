"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NOME = exports.LOGIN = exports.EMAIL = exports.DATA_NASCIMENTO = exports.CPF = void 0;
var NOME = 'nome';
exports.NOME = NOME;
var DATA_NASCIMENTO = 'dataNascimento';
exports.DATA_NASCIMENTO = DATA_NASCIMENTO;
var CPF = 'cpf';
exports.CPF = CPF;
var LOGIN = 'login';
exports.LOGIN = LOGIN;
var EMAIL = 'email';
exports.EMAIL = EMAIL;
import React, { useEffect, useState } from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
    SttMenuItem,
    SttBox
} from '@stt-componentes/core';
import Util from './../util';
import { MODULOS_CONSTANTES } from '../common/AppConstants';
import { Cache } from '@stt-componentes/cache';
import { useQuery } from '../hooks';
const useStyles = makeStyles(theme => ({
    boxMenu: {
        margin: '10px',
        padding: 0
    },
    container: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap'
    }
}));

const StyledMenuItem = withStyles(theme => ({
    root: {
        display: 'flex',
        width: '50%',
        flexDirection: 'column',
        transform: 'scale(0.80)'
    },
}))(SttMenuItem);

const MenuModulosManager = () => {
    const classes = useStyles();
    const [modulosFormatados, setModulosFormatados] = useState(null);
    const query = useQuery();
    const moduloAtual = query.get('moduloAtual');

    useEffect(() => {
        async function fetchModules() {
            const userInfo = await Cache.getUserInfoAsync(global.gConfig);
            var modulos = [];

            if (userInfo?.modulos) {
                userInfo.modulos.forEach(modulo => {
                    if (MODULOS_CONSTANTES[modulo] && global.gConfig.urls_modulos[modulo] && (MODULOS_CONSTANTES[modulo] !== moduloAtual || !moduloAtual)) {
                        var moduloFormatado = {};
                        moduloFormatado.icone = Util.retornarIconeModulo(modulo, true);
                        moduloFormatado.onClick = () => { window.open(global.gConfig.urls_modulos[modulo]) };
                        moduloFormatado.name = MODULOS_CONSTANTES[modulo];
                        modulos.push(moduloFormatado);
                    }
                });
                modulos.sort((a, b) => a.name.localeCompare(b.name));
                setModulosFormatados(modulos);
            }
        }

        fetchModules();
    }, []);

    const renderItem = (item, index) => {
        if (item.icone && item.name !== '') {
            return (
                <StyledMenuItem onClick={item.onClick} key={index}>
                    <ListItemIcon>
                        {item.icone}
                    </ListItemIcon>
                    <ListItemText primary={item.name} disableTypography={false} primaryTypographyProps={{ style: { whiteSpace: "normal", textAlign: 'center', marginTop: '10px' } }} />
                </StyledMenuItem>
            );
        }
    }

    return (
        <SttBox className={classes.boxMenu}>
            <div className={classes.container}>
                {
                    modulosFormatados &&
                    <>
                        {
                            modulosFormatados.map((dado, index) => renderItem(dado, index))
                        }
                    </>
                }
            </div>
        </SttBox>
    );
};

export default MenuModulosManager;

import React, { useState, memo, useEffect } from "react";
import {
    SttContainer,
    SttLoading,
    SttAlerta,
    SttGrid,
    SttButton,
    SttPaper,
    SttBox,
    SttHeading,
    SttNotification,
    SttFileSelect,
    SttTranslate
} from '@stt-componentes/core';
import Avatar from '@material-ui/core/Avatar';
import HttpStatus from 'http-status-codes';
import {
    Formik,
    Field,
    FieldArray,
} from 'formik';
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios';
import { getBearerHeaders } from '../requests';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

const validationSchema = (strings) => {
    return yup.object().shape({
        'fotos': yup
            .array()
            .max(1)
            .of(
                yup
                    .mixed()
                    .nullable()
                    .test('tamanhoArquivo', strings.erroTamanhoArquivo10Mb, (value) => {
                        if (!value || !(value instanceof File)) {
                            return true;
                        }
                        return value?.size <= 10e6;
                    })
                    .test('formatoArquivo', strings.formatoArquivoInvalido, (value) => {
                        if (!value || !(value instanceof File)) {
                            return true;
                        }
                        return value?.type === 'image/jpeg';
                    })
            )
    })
}

const useStyles = makeStyles(theme => ({
    grid: {
        marginBottom: theme.spacing(5)
    },
    formColumn: {
        backgroundColor: theme.palette.background.default,
        border: '2px solid',
        borderColor: '#D9D9D9',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        width: '80%',
        marginTop: theme.spacing(5)
    },
    expansionPanel: {
        width: '100%'
    },
    avatar: {
        width: '100%',
        height: 500,
    },
    avatarFoto: {
        minWidth: 300,
        minHeight: 300,
        width: '100%'
    },
    divExterna: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        overflowY: 'hidden'
    },
    box: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    notificacao: {
        marginBottom: theme.spacing(1.5),
    },
    alert: {
        margin: theme.spacing(2)
    },
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    heading: {
        marginBottom: theme.spacing(1.5),
        marginTop: theme.spacing(1.5),
    },
}));

const FileSelect = memo((props) => {
    return (
        <SttFileSelect {...props} headers={getBearerHeaders()} />
    )
});

const Alerta = memo((props) => {
    return (
        <SttAlerta {...props} />
    )
});

const MeusDadosFormFoto = ({ strings, user }) => {
    const classes = useStyles();

    const [initialValues, setInitialValues] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);

    //Alerta
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [opcoesAlerta, setOpcoesAlerta] = useState([{
        title: strings.ok,
        onClick: () => {
            setMostrarAlerta(false);
        }
    }]);
    const [onClose, setOnClose] = useState(() => setMostrarAlerta(false));

    useEffect(() => {
        if (user.id) {
            if (user.foto) {
                setInitialValues({
                    fotos: [{
                        nome: strings.fotoUsuario,
                        url: `${global.gConfig.url_base_api_oauth2}/interno/user-image`
                    }]
                });
                axios.get(`${global.gConfig.url_base_api_oauth2}/interno/user-image`, { headers: getBearerHeaders(), responseType: 'blob' })
                    .then((response) => {
                        const blob = response.data;
                        const file = new File([blob], strings.fotoUsuario, { lastModified: new Date().getTime(), type: blob.type });
                        setImageSrc(URL.createObjectURL(file));
                    })
                    .catch(err => {
                        setTituloAlerta('Erro');
                        setMensagemAlerta('Ocorreu um erro no download do arquivo. Tente novamente mais tarde.');
                        setOpcoesAlerta([
                            {
                                title: 'Ok',
                                onClick: () => setMostrarAlerta(false)
                            }
                        ]);
                        setMostrarAlerta(true);
                        setOnClose(() => () => setMostrarAlerta(false));
                    });
                return;
            }
            setInitialValues({
                fotos: [{}]
            });
        }
    }, [user.id]);

    const enviarForm = (dados, setSubmitting) => {
        const oauthBaseUrl = global.gConfig.url_base_api_oauth2;
        setMostrarAlerta(false);
        setSubmitting(true);

        const formData = new FormData();
        if (dados.fotos[0]?.name) {
            formData.append(`image`, dados.fotos[0]);
        }
        if (dados.fotos[0]?.nome) {
            formData.append(`sameImage`, 1);
        }

        axios.post(`${oauthBaseUrl}/interno/update-user-image`, formData, { headers: { ...getBearerHeaders(), 'Content-Type': 'multipart/form-data' } })
            .then(function (response) {
                const { data } = response;
                setSubmitting(false);
                setTituloAlerta(strings.sucesso);
                setMensagemAlerta(data?.message || strings.operacaoSucesso);
                setTipoAlerta('success');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => window.location.reload()
                    }
                ]);
                setOnClose(() => () => window.location.reload());
                setMostrarAlerta(true);
            })
            .catch(function (err) {
                const { response } = err;

                setSubmitting(false);
                let mensagem = strings.erro;
                if (response) {
                    const { data } = response;
                    mensagem = data.message;
                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        if (data.errors) {
                            data.errors.forEach(error => {
                                arrMensagem.push(`- ${error.message}`);
                            });
                        }
                        if (arrMensagem.length > 0) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }
                setTituloAlerta(strings.erro);
                setMensagemAlerta(mensagem);
                setTipoAlerta('error');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => setMostrarAlerta(false)
                    }
                ]);
                setOnClose(() => () => setMostrarAlerta(false));
                setMostrarAlerta(true);
            });
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <SttPaper className={classes.formColumn}>
                    <SttBox className={classes.box} letterSpacing={1}>
                        <SttHeading variant="h2" color="primary" className={classes.heading}>
                            {strings.alteracaoFoto}
                        </SttHeading>
                        {
                            initialValues &&
                            <Formik
                                initialValues={initialValues}
                                validateOnChange={false}
                                validationSchema={validationSchema(strings)}
                                onSubmit={(dados, { setSubmitting, resetForm }) => {
                                    setSubmitting(false);
                                    setTituloAlerta(strings.atencao);
                                    setMensagemAlerta(strings.confirmarFoto);
                                    setTipoAlerta('alert');
                                    setOpcoesAlerta([
                                        {
                                            title: strings.sim,
                                            onClick: () => enviarForm(dados, setSubmitting, resetForm)
                                        },
                                        {
                                            title: strings.nao,
                                            onClick: () => setMostrarAlerta(false)
                                        }
                                    ]);
                                    setOnClose(() => () => setMostrarAlerta(false));
                                    setMostrarAlerta(true);
                                }}
                            >
                                {
                                    ({
                                        isSubmitting,
                                        values,
                                        handleSubmit,
                                        setFieldValue
                                    }) => (
                                        <>
                                            <SttContainer>
                                                <form onSubmit={handleSubmit} noValidate>
                                                    <SttGrid container spacing={1} justifyContent="center">
                                                        <SttGrid item xs={12} md={8} lg={5}>
                                                            <SttGrid container spacing={1} justifyContent="center">
                                                                <SttGrid item xs={12}>
                                                                    <FieldArray name={'fotos'} render={
                                                                        () => (
                                                                            <div className={classes.divExterna}>
                                                                                {
                                                                                    values.fotos.map((anexo, indice) => (
                                                                                        <Field key={indice} name={`fotos.${indice}`}>
                                                                                            {({
                                                                                                field: { name, value },
                                                                                                form: { validateField },
                                                                                                meta
                                                                                            }) => (
                                                                                                <FileSelect
                                                                                                    onFileChange={(event) => {
                                                                                                        //Caso o anexo tenha sido excluído
                                                                                                        if (!event) {
                                                                                                            setFieldValue(`fotos[${indice}]`, {});
                                                                                                            setImageSrc(null);
                                                                                                            return;
                                                                                                        }

                                                                                                        if (event.target.files[0]) {
                                                                                                            const file = event.target.files[0];
                                                                                                            const imagem = new Image();
                                                                                                            imagem.src = URL.createObjectURL(file);

                                                                                                            imagem.onload = function () {
                                                                                                                const largura = this.width;
                                                                                                                const altura = this.height;

                                                                                                                if (largura > 2000 || altura > 2000) {
                                                                                                                    setTituloAlerta(strings.atencao);
                                                                                                                    setMensagemAlerta(strings.fotoGrande);
                                                                                                                    setTipoAlerta('alert');
                                                                                                                    setOpcoesAlerta([
                                                                                                                        {
                                                                                                                            title: strings.ok,
                                                                                                                            onClick: () => setMostrarAlerta(false)
                                                                                                                        }
                                                                                                                    ]);
                                                                                                                    setOnClose(() => () => setMostrarAlerta(false));
                                                                                                                    setMostrarAlerta(true)
                                                                                                                } else {
                                                                                                                    //Seta o valor da imagem no campo correspondente do formulário
                                                                                                                    setFieldValue(`fotos[${indice}]`, file);
                                                                                                                    validateField('fotos');
                                                                                                                    setImageSrc(imagem.src);
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }}
                                                                                                    file={value}
                                                                                                    urlDownload={anexo.url ? anexo.url : null}
                                                                                                    inputprops={{
                                                                                                        name: name,
                                                                                                        label: `${strings.formatosArquivo} - ${strings.dezMb}`,
                                                                                                        value: value?.name || value?.nome,
                                                                                                        error: meta.touched && meta.error ? meta.error : undefined
                                                                                                    }}
                                                                                                    accept={['image/jpeg']}
                                                                                                />
                                                                                            )}
                                                                                        </Field>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    />
                                                                </SttGrid>
                                                                <SttGrid item xs={12} >
                                                                    <SttNotification severity="info" className={classes.notificacao}>
                                                                        {strings.instrucao}
                                                                    </SttNotification>
                                                                    {
                                                                        imageSrc ?
                                                                            <img className={classes.avatarFoto} src={imageSrc} />
                                                                            :
                                                                            <Avatar className={classes.avatar} variant='square' />
                                                                    }
                                                                </SttGrid>
                                                            </SttGrid>
                                                            <div className={classes.buttonWrapper}>
                                                                <SttButton
                                                                    type="submit"
                                                                    variant="contained"
                                                                    nomarginleft="true"
                                                                    className={classes.button}
                                                                    color="primary"
                                                                    disabled={isSubmitting}
                                                                >
                                                                    {strings.salvar}
                                                                </SttButton>
                                                            </div>
                                                        </SttGrid>
                                                    </SttGrid>
                                                </form>
                                                <SttLoading
                                                    open={isSubmitting}
                                                    text={strings.salvandoMensagemEspera}
                                                />
                                            </SttContainer>
                                        </>
                                    )
                                }
                            </Formik>
                        }

                    </SttBox>
                </SttPaper>
            </div>
            <Alerta
                open={mostrarAlerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={onClose}
            />
        </>

    );
}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps)(SttTranslate('MeusDadosFormFoto')(withRouter(MeusDadosFormFoto)));
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    SttGrid,
    SttTable,
    SttTableBody,
    SttTableCell,
    SttTableHead,
    SttTableRow,
    SttHeading,
    SttHidden,
    SttText,
} from '@stt-componentes/core';
import { withRouter } from "react-router-dom";
import translate from '../../translate';
import { sortByName } from '../../common/Functions';

const useStyles = makeStyles((theme) => ({
    table: {
        overflow: 'auto',
    },
    heading: {
        marginBottom: theme.spacing(1.5),
        marginTop: theme.spacing(1.5),
    },
    semResultado: {
        fontWeight: 'bold'
    },
    label: {
        alignSelf: 'center',
        marginLeft: '5px'
    }
}));

const MeusDadosInstituicao = ({ instituicoes, strings }) => {
    const classes = useStyles();

    return (
        <SttGrid container style={{ marginBottom: 12 }}>
            <SttGrid item xs={12}>
                <SttHeading variant="h4" color="primary" className={classes.heading}>
                    {strings.instituicao}
                </SttHeading>
            </SttGrid>
            <SttGrid item xs={12}>
                <SttTable stickyHeader className={classes.table}>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden xsDown>
                                <SttTableCell width="90%">{strings.nome}</SttTableCell>
                                <SttTableCell width="10%">{strings.cnes}</SttTableCell>
                            </SttHidden>
                            <SttHidden smUp>
                                <SttTableCell width="100%">{strings.instituicao}</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            instituicoes.length > 0 ? (
                                instituicoes.sort(sortByName).map((inst) => (
                                    <SttTableRow key={inst.id}>
                                        <SttHidden xsDown>
                                            <SttTableCell>{inst.nome}</SttTableCell>
                                            <SttTableCell>{inst.cnes}</SttTableCell>
                                        </SttHidden>
                                        <SttHidden smUp>
                                            <SttTableCell width="100%">
                                                <SttText size="small">
                                                    <b>{strings.nome}:</b> {inst.nome}
                                                </SttText>
                                                <SttText size="small">
                                                    <b>{strings.cnes}:</b> {inst.cnes}
                                                </SttText>
                                            </SttTableCell>
                                        </SttHidden>
                                    </SttTableRow>
                                ))
                            ) : (
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={2}
                                        align="center"
                                        className={classes.semResultado}>
                                        {strings.nenhumRegistroEncontrado}
                                    </SttTableCell>
                                </SttTableRow>
                            )
                        }
                    </SttTableBody>
                </SttTable>
            </SttGrid>
        </SttGrid>
    );
};

export default translate('MeusDadosInstituicao')(withRouter(MeusDadosInstituicao));
/**
 * Camada de abstração do axios. Dois tipos de requisições:
 * 1. Requisições com interceptadores (Token e refreshToken).
 * 2. Requisições livres. 
 */
import { Cache } from '@stt-componentes/cache';
import axios from 'axios';

export const getHeaders = (token) => ({
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json'
});

export const getAuthrizationHeader = () => {
    const token = Cache.getAccessToken();
    return { authorization: `bearer ${token}` };
}

export const getBearerHeaders = () => {
    const token = Cache.getAccessToken();
    return { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };
}

const getHeadersLocalStorage = () => {
    const token = Cache.getAccessToken();
    return { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };
};

export default (config) => {
    const BASE_URL = config.url_base_api_oauth2;

    /**
     * Resuisições com intercepador.
     */
    const me = async (token) => axios.get(`${BASE_URL}/me`, { headers: getHeaders(token) });

    /**
     * Requisições sem interceptador.
     */
    const logout = async () => axios.get(`${BASE_URL}/logout`, { headers: getHeadersLocalStorage() });

    return { me, logout };
};

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    nomeModulo: {
        marginTop: '15px'
    },
    divExterna: {
        paddingTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
        width: '100%',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f0f0f0',
            borderRadius: '16px'
        },
    },
    divInterna: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center",	
    }
}));

export default function ModuleComponent({ name, icone, onClick }) {
    const classes = useStyles();

    return (
        <div className={classes.divExterna} onClick={onClick}>
            <div className={classes.divInterna}>
                {icone}
                <Typography variant='h4' align="center" className={classes.nomeModulo}>
                    {name}
                </Typography>
            </div>
        </div>
    )
}
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { SttHeading, SttInput, SttButton, SttAlerta } from '@stt-componentes/core';
import translate from './../translate';

/**
 * Definição de estilos específicos da página
 */
const useStyles = makeStyles(theme => ({
    inputSenha: {
        marginTop: '20px;'
    },
    button: {
        marginTop: '20px;'
    },
    heading: {
        marginTop: '20px',
        marginBottom: '30px'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start'
    }
}));

/**
 * Componente de login, com formulário e opção de recuperação de senha
 *
 * @param {Object} props
 */
const FormNovaSenha = (props) => {
    const { token, strings } = props;

    // Classes css
    const classes = useStyles();

    // Acesso à propriedade history do navegador
    const history = useHistory();

    // Variáveis de estado
    const [alertType, setAlertType] = useState('success');
    const [alert, showAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    const handleClose = () => {
        showAlert(false);
    };

    const [alertOptions, setAlertOptions] = useState([
        {
            title: strings.fechar,
            onClick: handleClose
        }
    ]);

    // Esquema de validação do formulário
    const schema = Yup.object().shape({
        password: Yup.string(strings.senhaObrigatoria).required(strings.senhaObrigatoria),
        password2: Yup.string(strings.senhaObrigatoria).required(strings.senhaObrigatoria)
    });

    // Submete os dados para recuperação da senha
    const resetPassword = (values, { setSubmitting, resetForm }) => {
        const oauthBaseUrl = global.gConfig.url_base_api_oauth2;
        axios
            .post(`${oauthBaseUrl}/auth/reset-password`, values)
            .then(function (response) {
                // Modifica a ação padrão do botão "Fechar" do alerta
                setAlertOptions([
                    {
                        title: strings.fechar,
                        onClick: () => history.replace('/')
                    }
                ]);

                const { data } = response;
                const msg = data.message;
                setAlertType('success');
                setAlertTitle(strings.sucesso);
                setAlertMessage(msg);
                showAlert(!alert);
            })
            .catch(function (error) {
                setAlertOptions([
                    {
                        title: strings.fechar,
                        onClick: handleClose
                    }
                ]);
                const { response } = error;

                const msg = response.data.message;
                setAlertType('error');
                setAlertTitle(strings.erro);
                setAlertMessage(msg);
                showAlert(!alert);
                setSubmitting(false);
            });
    };

    // Cria o formulário de autenticação
    return (
        <div>
            <Formik
                initialValues={{ password: '', password2: '', token: token }}
                validationSchema={schema}
                onSubmit={resetPassword}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit} noValidate className={classes.form}>
                        <SttHeading variant="h2"
                            color="primary"
                            className={classes.heading}>
                            {strings.redefinirSenha}
                        </SttHeading>
                        <SttInput
                            label={strings.novaSenha}
                            type="password"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            error={!!((touched.password && errors.password))}
                            className={classes.inputSenha}
                            helperText={touched.password && errors.password}
                        />
                        <SttInput
                            label={strings.repitaNovaSenha}
                            type="password"
                            name="password2"
                            value={values.password2}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            error={!!((touched.password2 && errors.password2))}
                            className={classes.inputSenha}
                            helperText={touched.password2 && errors.password2}
                        />
                        <input type="hidden" name="token" value={values.token} />
                        <SttButton
                            color="primary"
                            nomarginleft={1}
                            type="submit"
                            variant="contained"
                            disabled={isSubmitting}
                            className={classes.button}
                        >
                            {strings.salvar}
                        </SttButton>
                    </form>
                )}
            </Formik>
            <SttAlerta
                open={alert}
                title={alertTitle}
                message={alertMessage}
                type={alertType}
                options={alertOptions}
                onClose={handleClose}
            />
        </div>
    );
};

FormNovaSenha.propTypes = {
    token: PropTypes.string.isRequired
};
export default translate('FormNovaSenha')(FormNovaSenha);

import React, { useEffect, useState } from 'react';
import { Autenticacao, Cache } from '@stt-componentes/cache';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import { SttButton, SttText, SttBox, SttTheme } from '@stt-componentes/core';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import axios from 'axios';
import { getBearerHeaders } from '../requests';
const useStyles = makeStyles(theme => ({
    menuUsuario: {
        right: 10
    },
    boxMenuUsuario: {
        display: 'flex',
        flexFlow: 'column wrap',
        alignItems: 'center',
        padding: theme.spacing(4, 2, 4, 2),
        spacing: theme.spacing(4),
        width: '200px'
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        color: theme.palette.text.secondary
    },
    avatarFoto: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        color: theme.palette.text.secondary,
        border: '1px solid gray'
    },
    nome: {
        color: '#828282',
        fontSize: '0.85rem',
        fontWeight: '400',
        textAlign: 'center'
    },
    email: {
        color: '#828282',
        fontSize: '0.70rem',
        fontWeight: '400',
        textAlign: 'center'
    },
    botaoMeusDados: {
        marginTop: theme.spacing(2),
    },
    botaoSair: {
        marginTop: theme.spacing(2)
    }

}));


const limitarStr = (str, limit) => {
    const str_end = '...';
    if (str.length >= limit)
        return str.substring(0, limit - str_end.length) + str_end;
    else
        return str;
}

/**
 * Extrair primeiro e último nome.
 * Limitar chars por linha.
 */
const normalizarNome = (nome) => {
    const LIMIT_LENGTH = 25;
    const words = nome.split(" ");
    const size = words.length;
    if (size >= 2) {
        const primeiroNome = limitarStr(words[0], LIMIT_LENGTH);
        const ultimoNome = limitarStr(words[size - 1], LIMIT_LENGTH);
        const nomeNormalizado = `${primeiroNome.trim()} ${ultimoNome.trim()}`
        return nomeNormalizado;
    } else {
        return limitarStr(nome, LIMIT_LENGTH);
    }
};

/**
 * Limitar número de chars no email. 
 */
const normalizarEmail = (email) => {
    const LIMIT_LENGTH = 35;
    return limitarStr(email, LIMIT_LENGTH);
};

const MenuUsuario = ({ tituloSair = "Sair", tituloMeusDados = 'Alterar meus dados' }) => {
    const classes = useStyles();

    const [user, setUser] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        document.body.style.overflow = "hidden";
    }, []);

    useEffect(() => {
        const userInfo = Cache.getUserInfo();

        if (userInfo?.nome) {
            setUser(userInfo);
            if (userInfo.foto) {
                axios.get(`${global.gConfig.url_base_api_oauth2}/interno/user-image`, { headers: getBearerHeaders(), responseType: 'blob' })
                    .then((response) => {
                        if (response.data) {
                            const blob = response.data;
                            const fileURL = URL.createObjectURL(blob);
                            setImageSrc(fileURL);

                            // Ler o conteúdo da blob
                            var reader = new FileReader();
                            reader.onloadend = function () {
                                // Enviar o conteúdo da blob para o contêiner pai
                                // window.parent.postMessage(reader.result, "*");
                                const message = {
                                    "acao": "IMAGEM_USUARIO",
                                    "data": reader.result,
                                };

                                /** o event 'storage' só é captado por outras guias. E preciso enviar a mensagem para a guia atual manualmente. */
                                window.parent.postMessage(message, "*");
                                Cache.setEventBus(message);
                            };
                            reader.readAsDataURL(blob);

                        }
                    })
                    .catch(err => { console.log(err) });
            }
        }
    }, []);

    const handleClickMeusDados = () => {
        const eventbus = {
            "acao": "REDIRECT_MEUS_DADOS"
        };
        window.parent.postMessage(eventbus, "*"); // Envio local
    };

    const handleClickSair = () => {
        Autenticacao.logout(global.gConfig).finally(() => {
            const message = {
                "acao": "LOGOUT"
            };
            Cache.setEventBus(message); // Envio global (safari não funciona)
            window.parent.postMessage(message, "*"); // Envio local 
        });
    };

    if (!user) return <></>;

    return (
        <ThemeProvider theme={SttTheme}>
            <SttBox className={classes.boxMenuUsuario}>
                {
                    imageSrc ?
                        <Avatar src={imageSrc} className={classes.avatarFoto} />
                        :
                        <AccountCircleIcon className={classes.avatar} />
                }
                <SttText variant="h4" className={classes.nome}>{normalizarNome(user.nome)}</SttText>
                {
                    user.email &&
                    <SttText variant="h4" className={classes.email}>{normalizarEmail(user.email)}</SttText>
                }
                <SttButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.botaoMeusDados}
                    onClick={handleClickMeusDados}
                >
                    {tituloMeusDados}
                </SttButton>

                <SttButton
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.botaoSair}
                    onClick={handleClickSair}
                >
                    {tituloSair}
                </SttButton>
            </SttBox>
        </ThemeProvider>
    );
};

export default MenuUsuario;